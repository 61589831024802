import { Checkbox, Group, Text, Button } from "@mantine/core";
import { TodoId } from "~/db/schema";
import { SqliteBoolean } from "@evolu/common";
import { deleteTodo, toggleCompleted } from "~/db/todos";
import { Effect } from "effect";

export function Todo(props: {
  id: TodoId;
  title: string;
  completed: SqliteBoolean;
}) {
  const handleDelete = () => {
    deleteTodo(props.id).pipe(Effect.runPromise);
  };

  return (
    <Group w="100%" justify="space-between">
      <Group>
        <Checkbox
          checked={!!props.completed}
          onChange={() => {
            toggleCompleted(props.id).pipe(Effect.runPromise);
          }}
        />
        <Text
          style={{
            flexGrow: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {props.title}
        </Text>
      </Group>
      <Button onClick={handleDelete}>Delete</Button>
    </Group>
  );
}
