import { database, table, id, SqliteBoolean } from "@evolu/react";
import { Schema } from "@effect/schema";

export const todoId = id("todos");
export type TodoId = typeof todoId.Type;

export const NonEmptyString = Schema.String.pipe(
  Schema.minLength(1)
).annotations({
  message: () => "Must not be empty",
});

const todoTable = table({
  id: todoId,
  title: NonEmptyString,
  completed: SqliteBoolean,
});

export const dependencyId = id("dependencies");
export type DependencyId = typeof dependencyId.Type;

const dependenciesTable = table({
  id: dependencyId,
  from: todoId,
  to: todoId,
});

export type TodoTable = typeof todoTable;

export const Database = database({
  todos: todoTable,
  dependencies: dependenciesTable,
});
export type Database = typeof Database.Type;
