import { evolu, NonEmptyString, TodoId } from "./index";
import { cast } from "@evolu/common";
import { pipe, Effect, Match } from "effect";
import { NotNull } from "kysely";
import { Schema } from "@effect/schema";
import { query } from "./utils";

export const deletedTodos = query((db) =>
  db.selectFrom("todos").where("isDeleted", "=", cast(true)).selectAll()
);

export const nonDeletedTodos = query((db) =>
  db.selectFrom("todos").except(deletedTodos(db)).selectAll()
);

export const allTodos = query((db) =>
  db
    .selectFrom("todos")
    .where("todos.title", "is not", null)
    .where("todos.completed", "is not", null)
    .orderBy("completed", "asc")
    .orderBy("createdAt desc")
    .intersect(nonDeletedTodos(db))
    .selectAll()
    .$narrowType<{
      title: NotNull;
      completed: NotNull;
    }>()
);

function ign<T, R, A>(fn: (value: T) => R, val: T): (valTwo: A) => R {
  return (_: A) => fn(val);
}

export const completedTodos = query((db) =>
  db
    .with("all_todos", ign(allTodos, db))
    .selectFrom("all_todos")
    .where("completed", "=", cast(true))
    .orderBy("updatedAt", "desc")
    .selectAll()
);

export const nonCompletedTodos = query((db) =>
  db
    .with("all_todos", ign(allTodos, db))
    .selectFrom("all_todos")
    .where("completed", "!=", cast(true))
    .orderBy("updatedAt", "desc")
    .selectAll()
);

const singleTodo = (id: TodoId) =>
  query((db) =>
    db
      .with("valid_todos", ign(allTodos, db))
      .selectFrom("valid_todos")
      .where("valid_todos.id", "=", id)
      .limit(1)
      .selectAll()
  );

const getSingleTodo = (id: TodoId) =>
  Effect.gen(function* () {
    const result = yield* Effect.promise(() =>
      pipe(id, singleTodo, evolu.createQuery, evolu.loadQuery)
    );
    return result.row;
  });

export const createTodo = (title: string) =>
  Effect.gen(function* () {
    const parsedTitle = yield* Schema.decode(NonEmptyString)(title);
    const result = yield* Effect.sync(() =>
      evolu.create("todos", { title: parsedTitle, completed: false })
    );

    return yield* getSingleTodo(result.id);
  });

const filterNull = <A>(a: A | null) => {
  return Match.value(a).pipe(
    Match.when(Match.null, () => Effect.fail("Todo not found")),
    Match.orElse((x) => Effect.succeed(x))
  );
};

export const toggleCompleted = (id: TodoId) =>
  Effect.gen(function* () {
    const todo = yield* getSingleTodo(id);
    const nonNullTodo = yield* filterNull(todo);

    const result = yield* Effect.sync(() =>
      evolu.update("todos", { id, completed: !nonNullTodo.completed })
    );
    return yield* getSingleTodo(result.id);
  });

export const deleteTodo = (id: TodoId) =>
  Effect.sync(() => evolu.update("todos", { id, isDeleted: true }));
