import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider, createRouter } from "@tanstack/react-router";
import { MantineProvider } from "@mantine/core";

import { routeTree } from "./routeTree.gen";

const router = createRouter({ routeTree });

import "@mantine/core/styles.css";
import { EvoluProvider } from "@evolu/react";
import { evolu } from "./db";

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <EvoluProvider value={evolu}>
      <MantineProvider>
        <RouterProvider router={router} />
      </MantineProvider>
    </EvoluProvider>
  </StrictMode>
);
