import { Database as db, type Database } from "./schema";
import {
  createEvolu,
  useEvolu as useEvoluClient,
  useQuery,
} from "@evolu/react";
import { Row } from "@evolu/common";
import { type EvoluQueryCreator } from "./utils";
import { SelectQueryBuilder } from "kysely";

export const evolu = createEvolu(db, {
  // minimumLogLevel: "trace",
});

export const useEvolu = useEvoluClient<Database>;
export { useQuery, useOwner, useEvoluError, useSyncState } from "@evolu/react";
export function useCreateQuery<R extends Row>(
  callback: (db: EvoluQueryCreator) => SelectQueryBuilder<any, any, R>
) {
  const evolu = useEvolu();
  const query = evolu.createQuery<R>(callback);
  return useQuery<R>(query);
}

export * from "./schema";
