import { createFileRoute } from "@tanstack/react-router";
import { useCreateQuery } from "~/db";
import { completedTodos } from "~/db/todos";
import { Todo } from "~/components/Todo";
import { Stack } from "@mantine/core";

export const Route = createFileRoute("/completed")({
  component: () => <CompletedTodos />,
});

function CompletedTodos() {
  const todos = useCreateQuery(completedTodos);

  return (
    <div>
      <Stack gap="md">
        {todos.rows.map((todo) => (
          <Todo key={todo.id} {...todo} />
        ))}
      </Stack>
    </div>
  );
}
