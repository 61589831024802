import { createRootRoute, LinkProps, Outlet } from "@tanstack/react-router";
import { TanStackRouterDevtools } from "@tanstack/router-devtools";
import {
  AppShell,
  Burger,
  Text,
  Group,
  Container,
  NavLink,
  NavLinkProps,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useLinkProps } from "@tanstack/react-router";

import { IconListNumbers, IconReload, IconCheckbox } from "@tabler/icons-react";

function TanstackNavLink(
  props: Omit<NavLinkProps, "href"> & { to: LinkProps["to"] }
) {
  const stuff = useLinkProps({
    to: props.to,
    activeProps: {
      "aria-current": true,
    },
  });

  return <NavLink component="a" {...(props as any)} {...stuff} />;
}

export const Route = createRootRoute({
  component: Root,
});

function Root() {
  const [mobileOpened, { toggle: toggleMobile }] = useDisclosure(false);
  const [desktopOpened, { toggle: toggleDesktop }] = useDisclosure(true);

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 300,
        breakpoint: "sm",
        collapsed: { mobile: !mobileOpened, desktop: !desktopOpened },
      }}
    >
      <AppShell.Header>
        <Group h="100%" px="md">
          <Burger
            opened={mobileOpened}
            onClick={toggleMobile}
            hiddenFrom="sm"
            size="sm"
          />
          <Burger
            opened={desktopOpened}
            onClick={toggleDesktop}
            visibleFrom="sm"
            size="sm"
          />
          <Text>Charles</Text>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar p="md">
        <TanstackNavLink
          to="/"
          label="Queue"
          leftSection={<IconListNumbers size="1rem" stroke={1.5} />}
        />
        <TanstackNavLink
          to="/sync"
          label="Sync"
          leftSection={<IconReload size="1rem" stroke={1.5} />}
        />
        <TanstackNavLink
          to="/completed"
          label="Completed"
          leftSection={<IconCheckbox size="1rem" stroke={1.5} />}
        />
      </AppShell.Navbar>

      <AppShell.Main>
        <Container>
          <Outlet />
        </Container>
      </AppShell.Main>

      <TanStackRouterDevtools />
    </AppShell>
  );
}
